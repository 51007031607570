<template>
  <div>
    <ds-header title="banner位管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="标题">
        <a-input v-model.trim="searchForm.title" placeholder="请输入标题" class="width200" label-width="70px" />
      </a-form-item>
      <a-form-item label="位置">
        <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model="searchForm.show_location"
          class="width200">
          <a-select-option v-for="(item, index) in positionOptions" :key="index" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="状态">
        <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model="searchForm.status"
          class="width200">
          <a-select-option v-for="(item, index) in statusOptions" :key="index" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="排序方式">
        <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model="searchForm.order_by"
          class="width200">
          <a-select-option v-for="(item, index) in sortOptions" :key="index" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="更新日期">
        <a-range-picker :getCalendarContainer="(triggerNode) => triggerNode.parentNode" v-model="searchForm.time"
          format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" class="width200" />
      </a-form-item>
      <a-form-item label="透出端口">
        <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model="searchForm.show_port"
          class="width200">
          <a-select-option v-for="(item, index) in showPortOptions" :key="index" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="类型">
        <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model="searchForm.type"
          class="width200">
          <a-select-option v-for="(item, index) in typeOptions" :key="index" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="所属项目：">
        <el-cascader :getPopupContainer="(triggerNode) => triggerNode.parentNode" placeholder="请选择"
          v-model="searchForm.visible_project" :options="projectList" :props="fieldNames" clearable filterable
          size="mini" class="width200" collapse-tags>
        </el-cascader>
      </a-form-item>
      <a-form-item label="有效日期">
        <a-range-picker :getCalendarContainer="(triggerNode) => triggerNode.parentNode" v-model="searchForm.time1"
          format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" class="width200" />
      </a-form-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px"
      v-if="$checkButtonPermissions($buttonPermissionsConfig.popularizeManagement.bannerManagement.add)">
      <a-button type="primary" @click="goBannerEdit('add')">
        <a-icon type="plus" />
        添加推广位
      </a-button>
    </div>
    <div style="padding-top: 15px">
      <!-- @change="pageChange" -->
      <a-table :rowKey="(record, index) => index" :columns="table.columns" :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false" bordered :scroll="{ x: 1500 }"
        @change="pageChange">
        <span slot="action" slot-scope="text, record" class="action">
          <a-button class="btnClass" type="link" @click="goBannerEdit('look', record)"
            v-if="$checkButtonPermissions($buttonPermissionsConfig.popularizeManagement.bannerManagement.look)"> 查看
          </a-button>
          <a-button class="btnClass" type="link" @click="goBannerEdit('edit', record)"
            v-if="$checkButtonPermissions($buttonPermissionsConfig.popularizeManagement.bannerManagement.edit)"> 编辑
          </a-button>
          <a-button class="btnClass" type="link" @click="updateState(record)"
            v-if="$checkButtonPermissions($buttonPermissionsConfig.popularizeManagement.bannerManagement.enable)"> {{
          enableOrDisableText(record) }} </a-button>
          <a-button class="btnClass" type="link" @click="remove(record)"
            v-if="$checkButtonPermissions($buttonPermissionsConfig.popularizeManagement.bannerManagement.delete)"> 删除
          </a-button>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import help from './help'
import { mapState } from "vuex";
import * as api from '@/api/popularizeManagement/bannerManagement'
import { findCurrentUserAuthorityProjectListNew } from '@/api/common'
export default {
  computed: {
    ...mapState({
      isLoading: (state) => state.load.loading
    }),
  },
  data() {
    return {
      positionOptions: [{ value: '', label: '全部' }, ...help.positionOptions],
      statusOptions: [{ value: '', label: '全部' }, ...help.statusOptions],
      sortOptions: help.sortOptions,
      showPortOptions: [{ value: '', label: '全部' }, ...help.showPortOptions],
      typeOptions: [{ value: '', label: '全部' }, ...help.typeOptions],
      table: help.table,
      searchForm: {
        ...help.searchForm
      },
      fieldNames: { multiple: true, expandTrigger: 'hover', checkStrictly: true, emitPath: false, ...help.projectOptionsFieldNames },
      projectList: []
    };
  },
  methods: {
    /**查询数据处理 */
    // searchForm数据处理
    searchFormDispose() {
      const { time, time1, visible_project } = this.searchForm;
      return {
        ...this.searchForm,
        created_at: time.length ? `${time[0]} 00:00:00` : '',
        end_at: time.length ? `${time[1]} 23:59:59` : '',
        start_time: time1.length ? `${time1[0]} 00:00:00` : '',
        end_time: time1.length ? `${time1[1]} 23:59:59` : '',
        visible_project: visible_project.length ? visible_project : [],
        page: this.table.pagination.current - 1,
        size: this.table.pagination.pageSize
      }
    },
    // 启禁用按钮文案
    enableOrDisableText(record) {
      if (record.state === help.StatusEnum.ENABLE) {
        return help.StatusTextEnum[help.StatusEnum.DISABLED];
      } else {
        return help.StatusTextEnum[help.StatusEnum.ENABLE];
      }
    },
    /**网络请求 */
    // 获取banner列表
    async getList() {
      try {
        console.log(this.searchFormDispose());
        const res = await api.getBannerList(this.searchFormDispose());
        if (res.items && res.items) {
          this.table.dataInfo = [
            ...res.items
          ];
          this.table.pagination.total = res.total;
        }
      } catch (error) {
        console.log(error);
      }
    },

    // 启禁用banner请求
    async updateStateRequest(id, state) {
      try {
        const res = await api.updateState({
          id,
          state
        });
        this.getList();
        this.$messgae.success('操作成功')
      } catch (error) {

      }
    },
    // 删除banner请求
    async deleteBannerRequest(id) {
      try {
        const res = await api.deleteBanner(id);
        this.getList();
        this.$messgae.success('操作成功')
      } catch (error) {

      }
    },
    // 获取项目列表
    async getProjectList() {
      try {
        const res = await findCurrentUserAuthorityProjectListNew(true);
        if (res.code === this.$SUCCESS_CODE) {
          this.projectList = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    /**交互事件 */
    search() {
      // console.log(this.searchForm.time)
      this.getList();
    },
    resetSearchForm() {
      this.searchForm = {
        ...help.searchForm
      };
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.getList();
    },
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getList();
    },
    // 添加banner
    goBannerEdit(type, record = {}) {
      this.$router.push({
        path: '/popularizeManagement/bannerManagement/add',
        query: {
          type,
          id: record.id ? record.id : null
        }
      })
    },
    updateState(record) {
      let text = record.state === help.StatusEnum.ENABLE ? help.StatusTextEnum[help.StatusEnum.DISABLED] : help.StatusTextEnum[help.StatusEnum.ENABLE];
      const self = this;
      this.$confirm({
        title: `确认${text}当前banner？`,
        onOk() {
          self.updateStateRequest(record.id, record.state === help.StatusEnum.ENABLE ? help.StatusEnum.DISABLED : help.StatusEnum.ENABLE)
        },
        onCancel() {

        },
        class: 'test',
      });
    },
    remove(record) {
      const self = this;
      this.$confirm({
        title: `确认删除当前banner？`,
        onOk() {
          self.deleteBannerRequest(record.id)
        },
        onCancel() {

        },
        class: 'test',
      });
    }

  },
  created() {
    this.getList();
    this.getProjectList();
  },
};
</script>

<style lang="scss" scoped>
.width200 {
  width: 200px;
}

.btnClass {
  padding: 0px;
  padding-right: 5px;
}
</style>